<template>
  <b-card no-body>
    <b-card-body>
      <b-row align-h="between">
        <b-col md="5">
          <b-input-group class="input-group-merge position-relative">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <!-- Search field -->
            <b-form-input
              v-model="searchQuery"
              placeholder="Search"
              @input="search"
            />
            <!-- Reset keyword -->
            <b-input-group-append
              v-if="searchQuery"
              is-text
              class="cursor-pointer"
              @click="searchQuery = ''"
            >
              <feather-icon
                icon="XIcon"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col md="auto">
          <b-button
            block
            variant="primary"
            @click="showAddModal"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>Add Word</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>

    <b-table
      :items="paginatedItems"
      :fields="fields"
      show-empty
    >
      <template v-slot:cell(string)="data">
        <b-row align-h="between">
          <b-col>{{ data.item }}</b-col>
          <b-col
            cols="auto"
          >
            <b-button
              v-b-tooltip.v-danger.top="'Delete'"
              variant="flat-dark"
              class="btn-icon"
              size="sm"
              @click="confirmDelete(data.item)"
            >
              <feather-icon
                icon="TrashIcon"
                class="pointer-events-none"
                size="16"
              />
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-table>

    <b-card-body>
      <b-pagination
        v-model="currentPage"
        class="float-right"
        :total-rows="filteredItems.length"
        :per-page="itemsPerPage"
      />
    </b-card-body>

    <!-- Add Word modal -->
    <b-modal
      v-model="addModalVisible"
      title="Add Word"
      size="lg"
      hide-footer
    >
      <FormBuilder
        :fields="addWordFields"
        :loading-submit="$store.state.profanity.loadingSubmit"
        success-message="'The word has been added'"
        submit-label="Submit"
        add
        block
        @submit="submitWord"
        @cancel="addModalVisible = false"
      />
    </b-modal>

    <!-- Delete Word confirmation modal -->
    <b-modal
      v-model="deleteModalVisible"
      title="Delete User"
      ok-title="Yes, delete"
      cancel-variant="white"
      @ok="deleteWord"
    >
      Are you sure want to delete this data?. This action cannot be undone.
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BTable, BPagination, BFormInput, BButton, BCol,
  BRow,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from 'bootstrap-vue'
import FormBuilder from '@/layouts/components/FormBuilder.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BFormInput,
    BButton,
    BRow,
    BCol,
    FormBuilder,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 20,
      searchQuery: '',
      fields: [
        {
          key: 'string',
          label: 'Profanity',
        },
      ],
      addWordFields: [
        {
          key: 'word',
          label: 'Word',
        },
      ],
      deleteModalVisible: false,
      addModalVisible: false,
      selectedData: '',
    }
  },
  computed: {
    filteredItems() {
      if (this.searchQuery) {
        return this.$store.state.profanity.tableData.filter(item => item.toLowerCase().includes(this.searchQuery.toLowerCase()))
      }
      return this.$store.state.profanity.tableData
    },
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage
      return this.filteredItems.slice(startIndex, endIndex)
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    search() {
      this.currentPage = 1
    },
    loadData() {
      return this.$store.dispatch('profanity/getTableData')
    },
    confirmDelete(data) {
      this.selectedData = data
      this.deleteModalVisible = true
    },
    showAddModal() {
      this.addModalVisible = true
    },
    deleteWord() {
      this.$store.dispatch('profanity/delete', this.selectedData)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'The profanity word has been deleted!',
              variant: 'success',
            },
          })

          this.deleteModalVisible = false
          this.loadData()
        })
    },
    submitWord(data) {
      this.$store.dispatch('profanity/create', data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Add data success!',
              variant: 'success',
            },
          })

          this.addModalVisible = false
          this.loadData()
        })
    },
  },
}
</script>
